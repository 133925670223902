@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Flex:opsz,wght@8..144,100;8..144,200;8..144,300;8..144,400;8..144,500;8..144,600;8..144,700;8..144,800;8..144,900;8..144,1000&display=swap");

html {
  font-family: "Roboto Flex", sans-serif;
  font-weight: normal;
  color: $black;
  line-height: $baseLineHeight;
  font-size: 14px;

  @media only screen and (min-width: $smallBreakpoint) {
    font-size: 15px;
  }

  @media only screen and (min-width: $largeBreakpoint) {
    font-size: $baseFontSize;
  }
}

// Header Styles
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  margin: 0;
  margin-bottom: $pad;
  line-height: $headingLineHeight;

  a {
    font-weight: inherit;
  }
}
p {
  margin-bottom: $pad;
}

// Text Styles
em {
  font-style: italic;
}
strong {
  font-weight: bold;
}
.uppercase {
  text-transform: uppercase;
}
