// Fonts
$sansSerif: "Open Sans";

// Font Sizing
$baseFontSize: 16px;
$baseLineHeight: 1.5;
$headingLineHeight: 1.2;

// Colors
$black: rgba(51, 51, 51, 1);
$red: #d52b1e;
$blue: #00a1de;
$grey: #eeeeee;

// Spacing
$pad: 1em;

// Breakpoints
$smallBreakpoint: 600px;
$largeBreakpoint: 1080px;
