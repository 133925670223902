.content {
  width: auto;
  margin: 0;
}
.body {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: auto 1rem;
}
