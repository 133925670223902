// Card

.gameCard {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 15rem;
  margin: 1.5rem;
  text-align: center;

  @media (min-width: 375px) {
    margin: 1rem auto;
  }
  @media (min-width: 768px) {
    margin: 1rem auto;
  }

  img {
    max-height: 15rem;
    padding-bottom: 0.5rem;
    border-radius: 1rem;
  }

  ul {
    width: 100%;
    margin: 0 1rem;
  }
  li {
    text-align: left;
  }
}

.hover {
  box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.5);
}
