header {
  height: 20rem;
  width: 100%;
  margin: 2rem 0 2rem 0;
  text-align: center;
  align-items: center;
  background: url("../../images/wallpaper.webp") no-repeat center center;
  background-size: cover;

  h1 {
    display: flex;
    font-weight: 200;
    height: 100%;
    color: #fff;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 2rem;
  }
}
