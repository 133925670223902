// Footer Styles
footer {
  background: $grey;
  color: #111;
  padding: $pad * 2;
  text-align: center;

  div {
    @extend .container;
  }
}
